.container {
  width: 100%;
  height: 100vh;
  position: fixed;
  z-index: 10000;
  /* background: white url(assets/Fidget-spinner.gif) no-repeat center center; */
  background:rgba(255,255,255,0.8) url(assets/purple-loader.gif) no-repeat
  center center;
  
  /* backdrop-filter: blur(8px); */
}

.loadingMessage {
  font-size: 1.8rem;
  font-weight: 600;
  position: absolute;
  text-align: center;
  top: 39%;
  left: 50%;
  transform: translate(-50%, -9rem);

  color: #7215c9;
}
